<template>
  <div>
    <div class="checkout-container" ref="checkout_container" v-if="innerWidth > 769 && isValidLink">
      <div class="checkout-body" ref="checkout_body">
        <confirm />
      </div>
    </div>
    <div class="checkout-container-mobile"  v-if="innerWidth <= 769 && isValidLink">
      <account screen="MOBILE" v-if="$store.state.g25PartnerCheckout.mobilePageCode === 'ACCOUNT'"/>
      <confirm screen="MOBILE" v-if="$store.state.g25PartnerCheckout.mobilePageCode === 'CONFIRM'"/>
    </div>

    <div class="checkout-container">
      <div>
        <h4>
          Invalid Link
        </h4>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>{{ 'The link has already been used.' }}</span>
          </div>
        </b-alert>
      </div>
    </div>

  </div>
</template>

<script>
import AlertService from '@/common/alert.service';
import ApiService from '@/common/api.service';
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';
import useJwt from '@/auth/jwt/useJwt'

import Account from './steps/Account.vue';
import Confirm from './steps/Confirm.vue';

export default {
  components: {
    Confirm,
    Account,
  },
  data() {
    return {
      PRODUCT_CODES,
      G25_CHECKOUT_STEPS,

      isValidLink: true,
      currentRouteData: { step: '', productCode: '' },
      selectedProduct: null,

      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    }
  },
  methods: {
    onLoadPartnerLink() {
      /* eslint-disable */
      this.$store.commit('account/setIsLoggedIn', false);
      this.logout();
      this.isValidLink = true;

      const guid = this.$router.currentRoute.params.link_guid;
      
      this.$store.dispatch('g25PartnerCheckout/fetchPartnerLink', guid)
        .then(response => {
          if (response.statusCode === 200) {
            this.$store.commit('g25PartnerCheckout/setPartnerLinkData', response.result);
            this.$store.commit('account/updateUserEmail', response.result.email);

            this.onLoadProduct(response.result.productId);

            if (response.result.userGuid) {
              ApiService.get(`account/login/customer/${response.result.userGuid}`, {}, this)
              .then(responseLogin => {
                if (responseLogin.statusCode === 200) {
                  const userData = responseLogin.result
                  useJwt.setToken(responseLogin.result.token)
                  useJwt.setRefreshToken(responseLogin.result.token)
                  useJwt.setUserData(userData);
                  this.$store.commit('account/setIsLoggedIn', true);
                }
              });
            }
          } else {
            this.isValidLink = false;
          }
        });
      
    },
    onLoadProduct(productId) {
      this.$store.dispatch('products/fetchProductById', productId)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.productPrices.forEach(element => {
              element.label = response.result.product.productType === 1 ? `${element.productCount}` : `${element.productCount}`;
            });
            this.$store.commit('g25PartnerCheckout/setProductData', response.result);
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
  mounted() {
    this.onLoadPartnerLink();
  },
}
</script>

<style lang="scss">
// @import "../../purchase/purchase.scss";
</style>

<style lang="scss" scoped>
.checkout-container {
  padding: 2rem;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.checkout-container-mobile {
  background-color: #fff;
  height: 100%;
  padding: 1rem;
}
</style>
